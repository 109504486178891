import {message} from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import { Context } from "../../.."
import RubbleIcon from "../../../components/icons/RubbleIcon"
import {ApiService} from "../../../core/api/api"
import {useFetching} from "../../../core/hooks/useFetching"
import {XProduct} from "../../../core/models/store/product"
import {getCompound} from "../../../core/utils/store_utils"

import styles from "./styles/StoreProductCard.module.scss"

export interface StoreProductCardProps {
  product_id: number
  visible: boolean
  onClose: () => void
  onFetchData: () => void
}

const StoreProductCard: FC<StoreProductCardProps> = ({
  product_id = 0, visible = false, onClose, onFetchData
}) => {

  const { store, shopStore } = useContext(Context)

  const [product, setProduct] = useState(null as XProduct | null)
  const [amount, setAmount] = useState(0)

  const navigate = useNavigate()

  const fetchProduct = async (id: number) => {
    const res = await ApiService.store.getProduct(id)

    console.log(res)
    setProduct(res.data.product)
  }

  const addToCart = async () => {
    if (product == null) {
      return
    }

    if (product.actual_count == 0) {
      message.error("Товара нет в наличии!")
      return
    }

    if (!store.isAuth) {
      let props: any = {
        amount: 1,
        product_id: product.id,
        product: product,
        status: 1
      }

      let lst: any[] = [...shopStore.goods, props]

      localStorage.setItem("fermstory_goods_offline", JSON.stringify(lst))

      shopStore.setGoods(lst)

      message.success("Товар добавлен в корзину!")

      if (onFetchData != null) {
        onFetchData()
      }

      return
    }

    const res = await ApiService.store.addToBasket({
      product_id: product.id,
      user_id: store.userData.id,
      amount: 1,
      status: 1
    })

    console.log(res)

    message.success("Товар добавлен в корзину!")

    onFetchData()
  }

  const amountChange = async (type: number, amount: number) => {
    if (product != null) {
      const exists = shopStore.goods.find(v => v.product.id == product.id)
      if (exists != undefined) {
        if (type == 0 && exists.amount == 1) {
          if (store.isAuth) {
            const res = await ApiService.store.updateBasket({
              action: "delete",
              id: exists.id
            })
  
            console.log(res)
          } else {
            let lst: any[] = shopStore.goods.filter(v => v.product_id != product.id)
            console.log(lst, product)
            localStorage.setItem("fermstory_goods_offline", JSON.stringify(lst))
            shopStore.setGoods(lst)
          }

          message.success("Товар удален из корзины!")
        } else {
          if (store.isAuth) {
            const res = await ApiService.store.updateBasket({
              action: "update",
              data: {
                amount: type == 0 ? exists.amount - amount : exists.amount + amount,
              },
              id: exists.id
            })

            console.log(res)
          } else {
            let lst: any[] = []

            shopStore.goods.forEach(good => {
              let gd: any = {...good}
              if (good.product_id == product.id) {
                gd.amount = type == 0 ? exists.amount - amount : exists.amount + amount
              }
              lst.push(gd)
            })
            
            localStorage.setItem("fermstory_goods_offline", JSON.stringify(lst))
            
            shopStore.setGoods(lst)
          }
        }

        onFetchData()
      }
    }
  }

  const likeProduct = async () => {
    if (!store.isAuth) {
      message.error("Для начала авторизуйтесь!")
      return
    }

    if (product == null) {
      return
    }

    const res = await ApiService.store.likeProduct(product.id)
    console.log(res)

    message.success(res.data.message)

    let newLst: any[] = []

    shopStore.products.forEach(v => {
      let prd: any = {...v}
      if (product.id == v.id) {
        prd.in_favorite = !prd.in_favorite
      }
      newLst.push(prd)
    })

    shopStore.setProducts(newLst)

    fetchProduct(product.id)
  }

  const getCartAmount = () => {
    if (product != null) {
      const exists = shopStore.goods.find(v => v.product.id == product.id)
      if (exists != undefined) {
        return exists.amount
      }
    }
    return 0
  }

  const isInCart = () => {
    if (product != null) {
      const exists = shopStore.goods.find(v => v.product.id == product.id)
      if (exists != undefined) {
        return true
      }
    } 
    return false
  }

  const getFinalSum = () => {
    if (product != null) {
      let sum = getCartAmount() * (getFinalPrice(product) - ((getFinalPrice(product) / 100) * product.sale))
      return sum.toFixed(2)
    }
    return 0
  }

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
    }
  }

  const closeModal = () => {
    navigate("/catalog")
    onClose()
  }
  
  const getFinalPrice = (product: XProduct) => {
    if (product.compound == "item") {
      return product.price
    } else {
      return product.price / 10
    }
  }

  useEffect(() => {
    if (product_id != 0) {
      fetchProduct(product_id)
    }
  }, [product_id])

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  }, [])

  return (
    product != null ? <>
      <div className={styles.product_wrap} style={{
        opacity: visible ? "1" : "0"
      }}>
        <div className={styles.product_ph} onClick={() => closeModal()}></div>
        <div className={styles.product_body} style={{
          bottom: visible ? "0px" : "-100px"
        }}>
          <div className={styles.product_image}>
            <div className={styles.img} style={{backgroundImage: `url(${product.images.split(";")[0]})`}}>
              <div className={styles.close} onClick={() => closeModal()}><i className="fas fa-xmark"></i></div>
              <div onClick={() => likeProduct()} className={styles.favorite + (product.in_favorite ? " " + styles.active : "")}>
                <span style={{display: product.in_favorite ? "none" : "block"}}><i className="far fa-heart"></i></span>
                <span className={styles.liked} style={{display: product.in_favorite ? "block" : "none"}}><i className="fas fa-heart"></i></span>
              </div>
              {product.about_mini != null && product.about_mini != "" ? <div className={styles.tags}>{product.about_mini}</div> : <></>}
              {product.sale > 0 ? <div className={styles.sale}>-{product.sale}%</div> : <></>}
            </div>
          </div>
          <div className={styles.product_data}>
            <h1>{product.name}</h1>
            <h2>{product.cats.map(cat => cat.name).join(", ")}</h2>

            <div className={styles.product_price}>
              <div className={styles.basic + (product.sale > 0 ? " " + styles.sld : "")}>{getFinalPrice(product)} <span><RubbleIcon /></span> <small>/ {getCompound(product.compound)}</small></div>
              {product.sale > 0 ? <div className={styles.saled}>{getFinalPrice(product) - ((getFinalPrice(product) / 100) * product.sale)} <span><RubbleIcon /></span> <small>/ {getCompound(product.compound)}</small></div> : <></>}
            </div>
            
            {product.about != null && product.about != "" ? <div className={styles.data_block}>
              <div className={styles.data_block__label}>Описание</div>
              <div className={styles.data_block__data}>
                <div>{product.about}</div>
              </div>
            </div> : <></>}

            {product.brand != null ? <div className={styles.data_block}>
              <div className={styles.data_block__label}>Производитель</div>
              <div className={styles.data_block__data}>
                <div>{product.brand.name}</div>
                <div>{product.brand.about}</div>
                <div className={styles.image}><img src={product.brand.images.split(";")[0]} /></div>
              </div>
            </div> : <></>}
          </div>
          <div className={styles.product_cart}>
            {product.actual_count <= 0 ? <button className={styles.add_btn}>Нет в наличии</button> : <></>}
            {product.actual_count > 0 && !isInCart() ? <button className={styles.add_btn} onClick={() => addToCart()}>Добавить в корзину</button> : <></>}
            {product.actual_count > 0 && isInCart() ? <div className={styles.adding}>
              <button className={styles.adding_btn} onClick={() => amountChange(0, 1)}>-</button>
              <div>{getCartAmount()} <span>= {getFinalSum()} <RubbleIcon /></span></div>
              <button className={styles.adding_btn} onClick={() => amountChange(1, 1)}>+</button>
            </div> : <></>}
          </div>
          <div className={styles.product_rate}></div>
          <div className={styles.product_reviews}></div>
        </div>
      </div>
    </> : <></>
  )
}

export default observer(StoreProductCard)